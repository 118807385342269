import React, { useEffect } from 'react';
import { FAQJsonLd, GatsbySeo } from 'gatsby-plugin-next-seo';

const Faq: React.FunctionComponent = () => {



  useEffect(() => {
    const detailsList = document.querySelectorAll('details');
    detailsList.forEach((detail) => {
      const detailContent = detail.querySelector('div');
      const detailClosedHeight = detail.scrollHeight;
      detail.open = true;
      detailContent.style.setProperty(
        '--details-content-height-open',
        detailContent.scrollHeight + 'px'
      );
      detail.style.setProperty(
        '--details-height-open',
        detailContent.scrollHeight + detailClosedHeight + 'px'
      );
      detail.open = false;

      detailContent.style.setProperty(
        '--details-content-height-closed',
        detailContent.scrollHeight + 'px'
      );
      detail.style.setProperty(
        '--details-height-closed',
        detailClosedHeight + 'px'
      );

      detail.addEventListener('click', (e) => {
        const parent = e.target.parentElement;
        const timeout = getComputedStyle(
          parent.querySelector('div')
        ).getPropertyValue('--details-transition-time');

        parent.classList.toggle('is--open');
        if (parent.open) {
          e.preventDefault();
          setTimeout(function () {
            parent.open = false;
          }, parseInt(timeout));
        }
      });
    });
  }, []);

  
  return (
    <React.Fragment>
      <FAQJsonLd
        questions={[
          {
            question: 'Sono i prodotti davvero biologici?',
            answer:
              "I nostri prodotti vengono coltivati seguendo le regole della coltura biologica, per'altro non avremmo nemmeno i fondi necessari per comperare pesticidi",
          },
          { question: 'Quante persone abitano a Fraine', answer: 'Circa 200' },
          { question: 'Why?', answer: 'Peace' },
        ]}
      />
      <GatsbySeo
        title="FAQ"
        description="Una risposta alle domande piu` frequenti"
        canonical="https://www.casafraine.com/faq"
        openGraph={{
          url: 'https://www.casafraine.com/faq',
          title: 'Casa Fraine',
          description: 'Una risposta alle domande piu` frequenti',
          
          images: [
            {
              url: 'https://www.casafraine.com/mapitaly.png',
              width: 800,
              height: 600,
              alt: 'Botanic Api',
            },
            {
              url: 'https://www.casafraine.com/mapitaly.png',
              width: 900,
              height: 800,
              alt: 'CasaFraine',
            },
            { url: 'https://www.casafraine.com/mapitaly.png' },
            { url: 'https://www.casafraine.com/mapitaly.png' },
          ],
          site_name: 'Casa Fraine',
        }}
        twitter={{
          handle: '@handle',
          site: '@site',
          cardType: 'summary_large_image',
        }}
      />
      <div className="page-faq">
        <div className="main">
          <div className="faq">
            <div className="faq__image"></div>
            <div className="faq__main">
              <h1 className="faq__heading">FAQ</h1>
              <div className="faq__accordion">
                <label className="faq__label">
                  <input type="radio" name="question" className="faq__radio" />
                  <span className="faq__question">
                    How many team members can I invite?
                  </span>
                  <span className="faq__arrow"></span>
                  <span className="faq__answer">
                    You can invite up to 2 additional users on the Free plan.
                    There is no limit on team members for the Premium plan.
                  </span>
                </label>
                <label className="faq__label">
                  <input type="radio" name="question" className="faq__radio" />
                  <span className="faq__question">
                    What is the maximum file upload size?
                  </span>
                  <span className="faq__arrow"></span>
                  <span className="faq__answer">
                    No more than 2GB. All files in your account must fit your
                    allotted storage space.
                  </span>
                </label>
                <label className="faq__label">
                  <input type="radio" name="question" className="faq__radio" />
                  <span className="faq__question">
                    How do I reset my password?
                  </span>
                  <span className="faq__arrow"></span>
                  <span className="faq__answer">
                    Click "Forgot Password" from the Login page, or "Change
                    Password" from your profile page. A reset link will then be
                    emailed to you.
                  </span>
                </label>
                <label className="faq__label">
                  <input type="radio" name="question" className="faq__radio" />
                  <span className="faq__question">
                    Can I cancel my subscription?
                  </span>
                  <span className="faq__arrow"></span>
                  <span className="faq__answer">
                    Yes, you can. Send us a message and we will process your
                    request, no questions asked.
                  </span>
                </label>
                <label className="faq__label">
                  <input type="radio" name="question" className="faq__radio" />
                  <span className="faq__question">
                    Do you provide additional support?
                  </span>
                  <span className="faq__arrow"></span>
                  <span className="faq__answer">
                    Chat and email support is available 24/7. Phone lines are
                    open during normal business hours.
                  </span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default React.memo(Faq);
